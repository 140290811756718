<!--
 * @Author: wh
 * @Date: 2020-10-14 09:48:39
 * @LastEditTime: 2021-02-03 11:40:15
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \Web\JT.Web\src\views\productionManagement\stockCheck\index.vue
-->
<template>
    <div class="stockCheck">
        <div class="query-select">
            <el-form :inline="true" class="demo-form-inline">
                <el-form-item label="场站">
                    <el-select
                        v-model="formData.station_id"
                        placeholder="请选择"
                        :disabled="stationNameList.length == 0"
                        @change="changeStation"
                    >
                        <el-option
                            v-for="item in stationNameList"
                            :key="item.station_id"
                            :label="item.name"
                            :value="item.station_id"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="生产机组">
                    <el-select
                        clearable
                        v-model="formData.team_id"
                        placeholder="请选择"
                    >
                        <el-option
                            v-for="item in lineData"
                            :key="item.no"
                            :label="item.name"
                            :value="item.no"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="料仓类型">
                    <el-select
                        clearable
                        v-model="formData.category_code"
                        placeholder="请选择"
                        @change="changeCategory"
                    >
                        <el-option label="物理料仓" :value="'F3-01-WL'"></el-option>
                        <el-option label="虚拟料仓" :value="'F3-01-XN'"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="原材料分类">
                    <el-select
                        clearable
                        v-model="formData.type_code"
                        placeholder="请选择"
                        @change="changeTypeCode"
                    >
                        <el-option
                            v-for="(item, index) in typeDicCode"
                            :key="`${item.code}_${index}`"
                            :label="item.name"
                            :value="item.code"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="原材料子类">
                    <el-select
                        clearable
                        v-model="formData.child_type_code"
                        placeholder="请选择"
                        @change="changeChildTypeCode"
                    >
                        <el-option
                            v-for="(item, index) in childTypeDicCode"
                            :key="`${item.code}_${index}`"
                            :label="item.name"
                            :value="item.code"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="原材料规格">
                    <el-select
                        clearable
                        v-model="formData.material_dic_code"
                        placeholder="请选择"
                    >
                        <el-option
                            v-for="(item, index) in materialDicCode"
                            :key="`${item.code}_${index}`"
                            :label="item.name"
                            :value="item.code"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="搜索">
                    <el-input v-model="formData.search_key" placeholder="请输入关键词"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button class="btns" type="primary" @click="search">
                        查询
                    </el-button>
                </el-form-item>
            </el-form>
            <el-button
                class="btns save-btn"
                type="primary"
                @click="save"
            >
                保存
            </el-button>
        </div>
        <div class="tableContent table-bg">
            <el-table
                stripe
                border
                :data="list"
                class="table-list"
                ref="multipleTable"
                height="calc(100% - 35px)"
                :highlight-current-row="true"
                @row-click="selateTable"
            >
                <el-table-column
                    key="select"
                    type="selection"
                    width="55"
                >
                </el-table-column>
                <el-table-column
                    prop="stock_code"
                    label="料仓编号"
                    width="180"
                >
                </el-table-column>
                <el-table-column
                    prop="stock_no"
                    label="料仓号"
                >
                </el-table-column>
                <el-table-column
                    prop="stock_name"
                    label="料仓名称"
                >
                </el-table-column>
                <el-table-column
                    prop="volume_max"
                    label="最大库存"
                >
                </el-table-column>
                <el-table-column
                    prop="volume_warn_today"
                    label="最低预警"
                >
                </el-table-column>
                <el-table-column
                    prop="volume_safe"
                    label="最高预警"
                >
                </el-table-column>
                <el-table-column
                    prop="volume_theory"
                    label="理论库存"
                >
                </el-table-column>
                <el-table-column
                    label="实际库存"
                >
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.volume_actual" @click.stop.native="inputChange"></el-input>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="check_date"
                    label="最近盘点时间"
                >
                </el-table-column>
                <el-table-column
                    prop="check_username"
                    label="盘点人"
                >
                </el-table-column>
            </el-table>
            <div class="page-con">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="formData.pageindex"
                    :page-sizes="[10, 20, 30, 40, 50]"
                    :page-size="formData.pagesize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="totalNum"
                >
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {},
    data() {
        return {
            // 查询条件
            formData: {
                category_code: '',
                station_id: '',
                team_id: '',
                type_code: '',
                child_type_code: '',
                material_dic_code: '',
                search_key: '',
                pageindex: 1,
                pagesize: 10,
            },
            totalNum: 0,
            list: [],
            // 场站数据
            stationNameList: [],
            // 生产机组数据
            lineData: [],
            // 原材料分类
            typeDicCode: [],
            // 原材料子类
            childTypeDicCode: [],
            // 原材料规格
            materialDicCode: [],
            // table多选
            tableSelectData: [],
        };
    },
    watch: {},
    computed: {},
    created() {},
    mounted() {
        this.init();
    },
    methods: {
        // 初始化页面
        init() {
            // 处理场站数据
            this.getStationNameList();

            // 判断有默认场站，拉取场站下生产机组
            if ((this.stationNameList || []).length > 0) {
                this.handleLineData();
            }
            this.handleTypecode();
            this.handleTableData();
        },
        // 查询
        search() {
            this.handleTableData();
        },
        // 保存
        async save() {
            this.tableSelectData = this.$refs.multipleTable.selection;
            const data = [];
            this.tableSelectData.map(item => {
                const obj = {
                    stock_id: item.id,
                    volume: item.volume_actual,
                };
                data.push(obj);
            });
            if (data.length > 0) {
                const res = await this.saveApi(data);
                if (res) {
                    this.$message.success('保存成功');
                    this.handleTableData();
                }
            } else {
                this.$message.warning('至少选中一条数据保存');
            }

        },
        // 点击行选中
        selateTable(row) {
            this.$refs.multipleTable.toggleRowSelection(row);
            this.tableSelectData = this.$refs.multipleTable.selection;
        },
        inputChange() {
        },
        // 获取接收场站下拉数据
        getStationNameList() {
            this.stationNameList = [];
            const station_Names = this.$takeTokenParameters('StationNames').split(',');
            const station_IDs = this.$takeTokenParameters('StationIds').split(',');
            if ((station_Names.length || []) > 0) {
                station_Names.forEach((item, index) => {
                    this.stationNameList.push({
                        name: item,
                        station_id: station_IDs[index],
                    });
                });
                this.formData.station_id = this.stationNameList[0].station_id;
            }
        },
        // 切换场站
        changeStation() {
            if (this.formData.station_id === '') {
                this.formData.team_id = '';
            }
            this.handleLineData();
        },
        // 切换原材料分类
        changeTypeCode() {
            this.formData.child_type_code = '';
            this.formData.material_dic_code = '';
            this.materialDicCode = [{ code: '', name: '请选择' }];
            this.handleTypeChildcode();
        },
        // 切换料仓类型
        changeCategory() {
            this.handleTypecode();
            this.childTypeDicCode = [];
            this.materialDicCode = [];
            this.formData.type_code = '';
            this.formData.child_type_code = '';
            this.formData.material_dic_code = '';
        },

        // 切换原材料子类
        changeChildTypeCode() {
            this.formData.material_dic_code = '';
            this.handleMaterialcode();
        },
        // 改变每页条数
        handleSizeChange(pagesize) {
            this.formData.pagesize = pagesize;
            this.handleTableData();
        },
        // 改变页码
        handleCurrentChange(cur) {
            this.formData.pageindex = cur;
            this.handleTableData();
        },
        // 处理生产机组下拉数据
        async handleLineData() {
            const res = await this.getLineData();
            if (res) {
                this.lineData = res;
            }
        },
        // 处理原材料分类数据
        async handleTypecode() {
            const res = await this.getTypeDicCode();
            if (res) {
                this.typeDicCode = [];
                this.typeDicCode = res;
            }
        },
        // 处理原材料子类
        async handleTypeChildcode() {
            const res = await this.getTypeChildDicCode();
            if (res) {
                this.childTypeDicCode = res;
            }
        },
        // 处理原材料规格
        async handleMaterialcode() {
            const res = await this.getMaterialDicCode();
            if (res) {
                this.materialDicCode = res;
            }
        },
        // 处理表格数据
        async handleTableData() {
            const res = await this.getList();
            if (res) {
                this.list = res.rows;
                this.totalNum = res.total;
            }
        },
        // 获取生产机组下拉数据
        getLineData() {
            const path = '/interfaceApi/baseinfo/stationmanger/get_line_list?stationId=' + this.formData.station_id;
            return this.$axios.$get(path, { defEx: true });
        },
        // 获取原材料分类
        getTypeDicCode() {
            const path = '/interfaceApi/procurement/stock/materialparenttype/getlist?category_code=' + this.formData.category_code;
            return this.$axios.$get(path, { defEx: true });
        },
        // 获取原材料子类
        getTypeChildDicCode() {
            const path = '/interfaceApi/procurement/stock/materialchildtype/getlist?parent_code=' + this.formData.type_code;
            return this.$axios.$get(path, { defEx: true });
        },
        // 获取原材料规格
        getMaterialDicCode() {
            const path = '/interfaceApi/procurement/stock/materialcode/getlist?parent_code=' + this.formData.child_type_code;
            return this.$axios.$get(path, { defEx: true });
        },
        // 获取列表数据
        getList() {
            const path = '/interfaceApi/procurement/stock/getList';
            return this.$axios.$post(path, this.formData, { defEx: true });
        },
        // 保存
        saveApi(data) {
            const path = '/interfaceApi/procurement/stock/batchcheck/add';
            return this.$axios.$post(path, data, { defEx: true });
        },
    },
};
</script>
<style lang="stylus">
.stockCheck
    height 100%
    padding .2rem
    .query-select
        position relative
        .btns
            background #2978ff
            padding 0 0.2rem
            border none
            line-height 0.36rem
        .save-btn
            position absolute
            right 0
            top .02rem
            background #1577D2
        .el-form--inline
            .el-form-item__content
                width 1.5rem
            .el-form-item
                margin-bottom .1rem
    .tableContent
        height calc(100% - 1.05rem)
    .page-con
        margin-top .1rem
        text-align right
</style>
